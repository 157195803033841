section.categories {
    background-image: url(/src/images/background.jpg);
    background-size: 100%;
    min-height: 78vh;
}

section.categories .wrapper {
    width: 95%;
    
}
section.categories hr {
    height: 4px;
    background-color: rgb(255,193,7);
}
section.categories article.intro {
    border-bottom: 4px solid rgb(255,193,7);
}

.deleteCat {
    border:none;
    padding: 5px 10px;
    color: red;
    border-radius: 5px;
}

tr {
    position: relative;
}

td span:first-child{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
}

td span.catName {
    margin-left: 50px;
}

td span button.editCat {
    height: 100%;
    width: 100%;
    background-color: rgb(255,193,7);
    border: 1px solid black;
}

article.cat-create {
    width: 50%;
    margin: 10px auto;
    border: 1px solid rgb(255,193,7);
    padding: 1em;
    background-color: rgb(0,0,0,.75);
    border-radius: 5px;
    position: relative;
}

article.cat-create button.close-button {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    color: white;
    padding: 5px 10px;
    border: none;
    font-size: 1.25em;
    transition: .25s ease;
}

article.cat-create button.close-button:hover {
    color: red;
}

article.cat-create input {
    background-color: black;
    border-color: rgb(255,193,7);
    margin: 5px 0;
    color: white;
}

article.cat-create {
    background-color: black;
}