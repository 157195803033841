@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Fonts
  font-family: 'Fira Code', monospace;
*/

nav.navbar {
  background-color: black;
}

.App {
  text-align: center;
  font-family: 'Fira Code', monospace;
}

.App main {
  min-height: 76vh;
}

span.brandSpan {
  font-size: 2em;
  font-weight: normal;
  position: relative;
  top: .15em;
  left: .15em;
  color: rgb(233, 236, 22);
}
a.navbar-brand {
  font-weight: bold;
  position: relative;
  bottom: .25em;
  font-size: 1.5em;
}
.navbar-dark .navbar-nav a.nav-link {
  color: white;
  font-size: 1.5em;
  margin: 0 .5em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.login {
    background-image: url(/static/media/todo.6f4489a7.jpg);
    background-size: cover;
    min-height: 84vh;
    background-position: center;
    padding-top: 25vh;
}

.login article {
    background-color: rgba(255,255,255,.65);
    width: 45%;
    margin: auto;
    padding: 3em;
    border-radius: 10px;
}

div.logout {
    background-color: black;
}

span.profile {
    color: white;
  
}
span.profile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px;
    border: 1px solid white;
    background-color: black;
}


section.todos {
    background-image: url(/static/media/background.f79bd225.jpg);
    background-size: 100%;
    min-height: 78vh;
}
section.todos hr {
    height: 4px;
    background-color: rgb(233,236, 22);
}
section.todos article.intro {
    border-bottom: 4px solid rgb(233,236, 22);
}
.taskGallery {
    padding: 3em 0;
}

div.singleTodo {
    width: 75%;
    padding: 1em;
    margin: 3px auto;
    border: 1px solid #5e5e5e;
    position: relative;
    background-color: rgba(255,255,255,.9);
    overflow:hidden;
}


div.singleTodo h1 {
    font-size: 2em;
    width: 80%;
}


div.singleTodo span {
    margin-left: 3em;
    font-size: .5em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: black;
    padding: 1.75em .75em;
    font-weight: bold;
    width: 25%;
    cursor: pointer;
}

article.todo-complete h2, article.todo-incomplete h2 {
    margin-bottom: 0;
}
span.todo-count {
    font-size: .5em;
    display: block;
}

div.singleTodo p {
    position: absolute;
    top: .25em;
    left:.5em;
    font-weight: bold;
    color: rgb(100,100,100); 
    font-size: .85em;
}

div.singleTodo div {
    margin: 0;
}

div.createTodo {
    padding: 0;
    position: relative;
    padding: 5px;
    background-color: black;
    border: 1px solid rgb(189, 189, 189);
}

div.createTodo div:first-child {
    width: 75%;   
}

div.createTodo div:first-child input{
    border-radius: 0;
    outline: none;
    border: none;
    background-color: transparent;
    color: rgb(233,236, 22);
    font-size:1.2em;
}

div.createTodo div:first-child hr {
    background-color: rgb(233,236, 22);
    margin:3px;
}
div.createTodo div:first-child select {
    background-color: transparent;
    border: none;
    margin-top: 0;
    color: #000;
    background-color: rgb(255,255,255);
    display:block;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    position:relative;
    right: 2px;
    border-radius: 0;
}

div.createTodo div:first-child select:active, div.createTodo div:first-child select:focus {
    border: none;
}
div.createTodo div:first-child option {
    background-color: transparent;
}

div.createTodo div:first-child input::-webkit-input-placeholder {
    color: rgb(233,236, 22);
    box-shadow: none;
}

div.createTodo div:first-child input:-ms-input-placeholder {
    color: rgb(233,236, 22);
    box-shadow: none;
}

div.createTodo div:first-child input::placeholder, div.createTodo div:first-child input:focus {
    color: rgb(233,236, 22);
    box-shadow: none;
}

div.createTodo div:last-child {
    width: 25%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}
div.createTodo button {
    width: 100%;
    height: 100%;
    border-radius: 0;  
    border: none;
    outline: none;
    background-color: white;
    color: black;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 1.5em;
    transition:ease-in-out .5s;
    border-left: 1px solid gray;
}

div.createTodo button:hover {
    background-color: rgb(233,236, 22);
    color: rgb(50,50,50);
    border-left: 1px solid rgb(233,236, 22)
}
.galleryButtons {
    width: 75%;
    margin: auto;
}

.galleryButtons .btn.btn-black{
    transition: .25s ease-in-out;
    background-color: white;
    color: black;
    font-weight: bold;
    letter-spacing: 1px;
}

.galleryButtons .btn.btn-black:hover {
    background-color: rgb(100,100,100);
    color: white;
}


.galleryButtons .btn {
    height: 4em;
    width: 13em;
    border-radius: 0;
}

#deleteLink {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 10px 15px;
    background-color: red;
    color: white;
    border: none;
    border-top-right-radius: 4px;
}

#deleteLink:hover {
    color: rgb(233,236, 22);
    border-color: rgb(233,236, 22);
    background-color: black;
}

@media only screen and (max-width: 1023px){
    div.singleTodo {
        width: 95%;
    }
    div.createTodo div:last-child {
        width: 25%;
    }
}



section.categories {
    background-image: url(/static/media/background.f79bd225.jpg);
    background-size: 100%;
    min-height: 78vh;
}

section.categories .wrapper {
    width: 95%;
    
}
section.categories hr {
    height: 4px;
    background-color: rgb(255,193,7);
}
section.categories article.intro {
    border-bottom: 4px solid rgb(255,193,7);
}

.deleteCat {
    border:none;
    padding: 5px 10px;
    color: red;
    border-radius: 5px;
}

tr {
    position: relative;
}

td span:first-child{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
}

td span.catName {
    margin-left: 50px;
}

td span button.editCat {
    height: 100%;
    width: 100%;
    background-color: rgb(255,193,7);
    border: 1px solid black;
}

article.cat-create {
    width: 50%;
    margin: 10px auto;
    border: 1px solid rgb(255,193,7);
    padding: 1em;
    background-color: rgb(0,0,0,.75);
    border-radius: 5px;
    position: relative;
}

article.cat-create button.close-button {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    color: white;
    padding: 5px 10px;
    border: none;
    font-size: 1.25em;
    transition: .25s ease;
}

article.cat-create button.close-button:hover {
    color: red;
}

article.cat-create input {
    background-color: black;
    border-color: rgb(255,193,7);
    margin: 5px 0;
    color: white;
}

article.cat-create {
    background-color: black;
}
.notFound{
    min-height: 84vh;
    background-image: url(/static/media/light.29e28a49.jpg);
    background-size: 85%;
    background-position: center;
    padding-top:75vh;
    color: white;
}
