.login {
    background-image: url(/src/images/todo.jpg);
    background-size: cover;
    min-height: 84vh;
    background-position: center;
    padding-top: 25vh;
}

.login article {
    background-color: rgba(255,255,255,.65);
    width: 45%;
    margin: auto;
    padding: 3em;
    border-radius: 10px;
}

div.logout {
    background-color: black;
}

span.profile {
    color: white;
  
}
span.profile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px;
    border: 1px solid white;
    background-color: black;
}

